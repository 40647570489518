
  export const style={
    inpuStyle:{
      colorInput:"#97A1A4"
    },
    url:{
      urlClient:"https://aterpa.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/logotipo_aterpa.png"
    },
    CurrencyName:{
      name:"AterpaCoin/AterpaCoins"
    },
    teamName:{
      name:"Aterpa"
    },
    hasToken: false,
    hostHeader: "aterpa"
  }
